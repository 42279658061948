import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import { GatsbyImage } from "gatsby-plugin-image"
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Obfuscate from 'react-obfuscate';
import logo from '../images/logo-light.svg';

const Home = (props) => {
  const markdown = props.data.allMarkdownRemark.edges;
  const json = props.data.allFeaturesJson.edges;

  return (
    <Layout bodyClass="page-home">
      <SEO title="Home" />
      <Helmet>
        <meta
          name="description"
          content='WXT Media sagt "Moin moin!". Konzeption, Gestaltung &amp; Umsetzung digitaler Medien.'
        />
      </Helmet>
      <div className="intro pb-4">
        <div className="container text-center text-md-left">
          <h1>WXT Media // Konzeption, Gestaltung &amp; Umsetzung digitaler Medien</h1>
          <h2 className="introtext">
            <span className="first-sentence">Hier in Hamburg sagt man "Moin"!&nbsp;✌️</span>
            Mein Name ist <strong><span className="gradient1">Ines Opifanti</span></strong>  und ich bin spezialisiert auf die Konzeption &amp; Umsetzung digitaler Medien.
          </h2>
          <h3 className="w-ex">Seit über 10 Jahren arbeite ich in diesem Bereich selbstständig für Agentur- und Konzernkunden in Deutschland. Schauen Sie sich gern um.</h3>
        </div>
      </div>

      <div className="container text-center text-md-left">
        <div className="row tier my-4">
          <div className="col-12">
          <h3 className="mb-2">Technology Scope</h3>
          </div>

          <div className="col-12 col-md-4">
            <h4>Visuelle Kommunikation</h4>
            <ul>
              <li>Konzeption &amp; Gestaltung digitaler Medien</li>
              <li>Konzeption &amp; Gestaltung von User Interfaces</li>
              <li>User Experience (UX) Design</li>
              <li>Konzeption, Analyse &amp; Optimierung der Nutzerführung / Customer Journey</li>
              <li>Prototyping &amp; Wireframes</li>
            </ul>
          </div>


          <div className="col-12 col-md-4">
            <h4>Web</h4>
            <ul>
              <li>Wordpress / Headless Wordpress</li>
              <li>React.js / Gatsby.js</li>
              <li>Front End Stacks inkl. HTML5, CSS3, Bootstrap, jQuery etc.</li>
              <li>WooCommerce, Shopify &amp; andere E-Commerce Plattformen</li>
              <li>Mobile first approach</li>
            </ul>
          </div>
         

          <div className="col-12 col-md-3">
            <h4>Applications</h4>
            <ul>
              <li>Progressive Web Apps mit z.B. React Native</li>
              <li>Swift / Swift UI for iOS</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container text-center text-md-left">
        <div className="row tier">
          <div className="col-12 clients my-4">
            <h3>Clients</h3>
            <p>
              BRUNO BANANI
              <span> // </span>
              CITROEN
              <span> // </span>
              COCA-COLA
              <span> // </span>
              DIE WELT
              <span> // </span>
              DEUTSCHE BAHN
              <span> // </span>
              DEUTSCHE WELLE
              <span> // </span>
              EA GAMES
              <span> // </span>
              EUROPCAR
              <span> // </span>
              FAKTOR3
              <span> // </span>
              FOLLOWFISH
              <span> // </span>
              GERLACH HARTOG
              <span> // </span>
              GOOGLE
              <span> // </span>
              HAMBURG AIRPORT
              <span> // </span>
              HEINE
              <span> // </span>
              KNORR
              <span> // </span>
              LEAGAS DELANEY INT.
              <span> // </span>
              LOGITECH
              <span> // </span>
              MICROSOFT
              <span> // </span>
              NIGRIN
              <span> // </span>
              NIVEA
              <span> // </span>
              OTTO
              <span> // </span>
              O2
              <span> // </span>
              PLANET A
              <span> // </span>
              SÜDDEUTSCHE ZEITUNG
              <span> // </span>
              THE GOOD EVIL
              <span> // </span>
              TIKTOK
              <span> // </span>
              &amp; many more...
            </p>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="row tier mb-0">
          <div className="col-12">
          <div className="logo-bottom">
                <img alt="WXT MEDIA // Konzeption, Gestaltung &amp; Umsetzung digitaler Medien." src={logo} />
            </div>
            <h3 className="mb-2">Kontakt</h3>
            <p>
            <span className='gradient1'><i className="fa fa-envelope-open-o fa-1x" /></span>
              &nbsp;&nbsp;&nbsp;
              <Obfuscate email="hi@wxt.media" />
            </p>
            <p>
            <span className='gradient1'><i className="fa fa fa-volume-control-phone fa-1x" /></span>
              &nbsp;&nbsp;&nbsp;
              <Obfuscate tel="+49 176 23 19 58 88" />
            </p>

            <p>
              <a
                href="https://www.xing.com/profile/Ines_Opifanti"
                className="gradientIcon"
                target="_blank"
              >
            <span className='gradient2'><i className="fa fa-xing fa-3x" aria-hidden="true"></i></span>
              </a>
            </p>

            
          </div>
        </div>
      </div>
      <svg width="0" height="0">
        <radialGradient id="rg" r="150%" cx="30%" cy="107%">
          <stop stopColor="#FF9A76" offset="0" />

          <stop stopColor="#e52e71" offset="0.9" />
        </radialGradient>
      </svg>
      <svg width="0" height="0">
        <radialGradient id="rg2" r="150%" cx="30%" cy="107%">
          <stop stopColor="#43E695" offset="0" />

          <stop stopColor="#3BB2B8" offset="0.9" />
        </radialGradient>
      </svg>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/apps/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            image {
              childImageSharp {
                fluid(quality: 95, maxWidth: 550) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            date(formatString: "DD MMMM YYYY")
          }
          excerpt(pruneLength: 220)
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
        }
      }
    }
  }
`;

export default Home;
